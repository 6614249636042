import React, { useEffect, useState } from 'react';
import BottomTab from "../Tab/BottomTab";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './DocumentDone.sass';
import { apiUser } from '../../basedata/apiCall';
import InfoModal from "../Modal/ModalInfo";

interface Props {
    documentBase64: string;
    onResponse: (message: string) => void;
    documentAttempts: number;
    isVerse: boolean;
}

let doc_check_retries = 0;
const DocumentDone = ({ documentBase64, onResponse, documentAttempts, isVerse }: Props) => {
    const { t } = useTranslation();
    const [isPanelOpen, setIsPanelOpen] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const togglePanel = () => {
        setIsPanelOpen((prev) => !prev);
    };


    const simulateLoading = () => {
      setIsLoading(true);
      setLoadingProgress(0);
      const interval = setInterval(() => {
          setLoadingProgress((prev) => {
              if (prev >= 100) {
                  clearInterval(interval);
              }
              return prev + 1;
          });
      }, 900);
  };


    const fetchDocumentStatus = async (biometryToken:any) => {

      setIsLoading(true);
      setLoadingProgress(0);

      const eventSource = new EventSource(`https://service-user-flamengo-563791290358.southamerica-east1.run.app/biometry/flow-events?token=${biometryToken}`);

      const interval = setInterval(() => {
          setLoadingProgress((prev) => {
              if (prev >= 100) {
                  clearInterval(interval);
                  eventSource.close();
              }
              return prev + 1;
          });
      }, 900);

      eventSource.onmessage = (event) => {
        const _response_data = JSON.parse(event.data)

        console.log(_response_data.status)

        if(_response_data?.status === 'error'){

          let _current_front_retries:any = Number(sessionStorage.getItem('doc_front_retries') || 0)
          _current_front_retries += 1
          sessionStorage.setItem('doc_front_retries', _current_front_retries)
 
    
           if(_current_front_retries >= 3){
             clearInterval(interval);
             return window.location.href = '/waiting'
           }

          if(_response_data?.message === 'face_match'){
            eventSource.close();
            clearInterval(interval);
            setError_message(`${t('capture_document.error_messages.face_match')}`)
            return setIsModalOpen(true)
          }else if(_response_data?.message === 'flow_not_started'){
            eventSource.close();
            clearInterval(interval);
            return handleConfirm()
          }
        }

        if(_response_data && _response_data.status === 'success'){
          sessionStorage.setItem('user_history', 'document_back')
          eventSource.close();
          clearInterval(interval);
          return onResponse('mobile-document-verse');
        }
      };
      
      eventSource.onerror = () => {
        console.log('Erro na conexão SSE. Tentando reconectar...');
        eventSource.close();
        setTimeout(() => {
          fetchDocumentStatus(biometryToken)
        }, 5000);
      };
    }

    const handleConfirm = async () => {
      
      setIsLoading(true);
      setLoadingProgress(0);
      
      const _create_document = await apiUser.post('/biometry/create/document', {
        originToken: sessionStorage.getItem('token') || 'na',
        side: isVerse ? 'documentBack':'documentFront',
        type: 'mix_cnh',
        imageBase64: documentBase64,
      })

      const interval = setInterval(() => {
        setLoadingProgress((prev) => {
            if (prev >= 100) {
                clearInterval(interval);
            }
            return prev + 1;
        });
    }, 900);
      
      if(isVerse){



      const _temp_token = sessionStorage.getItem('token')

        const _validate_doc = await apiUser.get(`/biometry/ocr-validation/image/back?uid=${_temp_token}`)

        let _current_front_retries:any = Number(sessionStorage.getItem('doc_back_retries') || 0)
        _current_front_retries += 1
        sessionStorage.setItem('doc_back_retries', _current_front_retries)

        if(
          _validate_doc?.data.status === 'success' &&
          _validate_doc?.data.message === 'approved'){
            clearInterval(interval);
            return window.location.href = '/success'
          }

          console.log('tste -- 0')
          if(_current_front_retries >= 3){
            clearInterval(interval);
            return window.location.href = '/waiting'
          }
                    
          sessionStorage.setItem('doc_front_retries', _current_front_retries)
          clearInterval(interval);
          setError_message(`${t('capture_document.error_messages.retries')}`)
          return setIsModalOpen(true)
      }else{

        console.log('tste -- 2')
        let _current_front_retries:any = Number(sessionStorage.getItem('doc_front_retries') || 0)
         _current_front_retries += 1
         sessionStorage.setItem('doc_front_retries', _current_front_retries)

        const _validate_doc = await apiUser.post('/biometry/document/match', {
          originToken: sessionStorage.getItem('token') || 'na',
        })

        if(_validate_doc?.data && _validate_doc?.data.error === 'fraud'){

          if(_current_front_retries >= 3){
            clearInterval(interval);
            return window.location.href = '/waiting'
          }
          clearInterval(interval);
          setError_message(`${t('capture_document.error_messages.generic')}`)
          return setIsModalOpen(true)
        }

        clearInterval(interval);
        setIsLoading(false);

        return fetchDocumentStatus(sessionStorage.getItem('token') || 'na')
      }

    };


    const handleRetry = async () => {
      const _temp_token = sessionStorage.getItem('token')

      // if(isVerse){
      //  return onResponse('retry_back');
      // }
      const _validate_doc = await apiUser.get(`/biometry/retry?uid=${_temp_token}`)
      sessionStorage.setItem('user_history', 'document_front')
      return window.location.reload()

    }
    const handleRecapture = () => {
      sessionStorage.removeItem('get_flow_index')
      return window.location.reload()
        // onResponse('documentCancel');
    };

    const get_doc_response = async () => {
      doc_check_retries++

      if(doc_check_retries >= 1){
        return onResponse('mobile-document-verse');
      }
      let _get_doc_status:any = await apiUser.post('/biometry/flow-status',{
        id: sessionStorage.getItem('token') || 'na',
      })

      _get_doc_status = _get_doc_status.data

      if(_get_doc_status?.status === 'success'){
        sessionStorage.setItem('image_check', 'ok')
      }else if(_get_doc_status?.status === 'processing'){
        setTimeout(() => {
          get_doc_response()
        }, 1000);
      }else{
        alert('Documento inválido. Erro 2910.2')
      }
    }
    // useEffect(() => {
    //     simulateLoading();
    // }, []);

    const titleKey = isVerse
        ? `well_done_document.verse_title_${documentAttempts + 1}` 
        : `well_done_document.front_title_${documentAttempts + 1}`;
    const messageKey = isVerse
        ? `well_done_document.verse_message_${documentAttempts + 1}`
        : `well_done_document.front_message_${documentAttempts + 1}`;

    const title = t(titleKey, t('well_done_document.front_default_title'));
    const message = t(messageKey, t('well_done_document.front_default_message'));


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error_message, setError_message] = useState('A sua selfie e a foto do documento não batem.');
    const closeModal = () => {
      setIsModalOpen(false);
    };

    return (
        <div className="container-selfie-done">
           <InfoModal isOpen={isModalOpen} onClose={closeModal}>
                <h1>Opa!</h1>
                <p>{error_message}</p>
                <button className='button-retry-action' onClick={() => handleRetry()}>{t('success_document.try_again')}</button>
            </InfoModal>
            <img src={documentBase64} alt="document" className="document-taken" />
            {isLoading ? (
                <div className="loader-container">
                    <div
                        className="loader-bar"
                        style={{ width: `${loadingProgress}%` }}
                    ></div>
                    <span className="loader-percentage">{loadingProgress}%</span>
                </div>
            ) : (
                <BottomTab togglePanel={togglePanel} isPanelOpen={isPanelOpen}>
                    <div className="selfie-confirmation-content">
                        <div className="selfie-confirmation-text">
                            {/* <h1>{title}</h1> */}
                            <p>{message}</p>
                        </div>
                        <div className="selfie-confirmation-buttons">
                            <div className="try-again-section">
                                <button onClick={handleConfirm}>{t("success_selfie.send_document")}</button>
                                <div className="try-again-link" onClick={handleRecapture}>
                                    <ArrowBackIcon />
                                    <p>{t("success_selfie.try_again")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </BottomTab>
            )}
        </div>
    );
};

export default DocumentDone;