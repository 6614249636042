import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import bepass_white_logo from "../../images/logoCompleto.png";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { useRegisterData } from './SignupStepper';
import { FormControl, Input, InputLabel, MenuItem, Select } from '@mui/material';
import { apiUser } from '../../basedata/apiCall';
import validator from 'validator';
import Flag from 'react-world-flags'


interface FormStepperInterface {
  onNext: () => void;
}

const SignupPartOne = ({onNext}:FormStepperInterface) => {

  const { i18n } = useTranslation();

  const {handleRegisterFormData} = useRegisterData()

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
    clearErrors
  } = useForm({
    reValidateMode: 'onChange',
  })

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [formData, setFormData] = useState({
        name: '',
        document: '',
        email: ''
    });

    // // const [errors, setErrors] = useState({
    // //     name: '',
    // //     document: '',
    // //     email: ''
    // // });


    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const { id, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [id]: value,
    //     }));

    // };

    // const handleCheckBoxChange = () => {
    //     setTermsAccepted((prevValue) => !prevValue);
    // };

    const documentValidation = (strCPF:any) => {
      var Soma;
      var Resto;
      Soma = 0;
    if (strCPF == "00000000000") return false;
  
    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
  
      if ((Resto == 10) || (Resto == 11))  Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
  
    Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;
  
      if ((Resto == 10) || (Resto == 11))  Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
      return true;
  }

    const formSubmitter = async (data:any) => {
      const _currentDoc = data.document.replaceAll('.','').replaceAll('-', '');

      if(data.nationality === 'br' && !documentValidation(_currentDoc)){
       return setError('document', {
          type: 'manual',
          message: 'Você precisa preencher ao menos 1 campo!'
        })
      }

      if(data.nationality !== 'br' && _currentDoc.length < 6){
        return setError('document', {
           type: 'manual',
           message: 'Você precisa preencher ao menos 1 campo!'
         })
       }

      if(!data.name){
        return setError('name', {
          type: 'manual',
          message: 'Você precisa preencher ao menos 1 campo!'
        })
      }


      let _has_record:any = await apiUser.post('/user/findUserByDocument', {
        document: data.document.replaceAll('.', '').replace('-', '')
      })

      _has_record = _has_record.data

      sessionStorage.setItem('token', _has_record.user?.id)
      sessionStorage.setItem("heartPartOne", JSON.stringify(data));


      if(_has_record && _has_record.status === 'success'){
        sessionStorage.removeItem('user_history')
        return window.location.href = `/validating?token=${_has_record.user?.id}`
      }



      return onNext()
      // handleRegisterFormData(data)
        // const newErrors = {
        //     name: formData.name ? '' : t('signup.errors.required'),
        //     document: formData.document ? '' : t('signup.errors.required'),
        //     email: formData.email ? '' : t('signup.errors.required'),
        // };

        // // setErrors(newErrors);

        // const hasErrors = Object.values(newErrors).some((error) => error);
        // if (!hasErrors && termsAccepted) {
        //     localStorage.setItem("signupPartOne", JSON.stringify(formData));
        //     navigate("/signup-part-two");
        // }
    };

    useEffect(() => {

      sessionStorage.removeItem('success_flow')
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    let isFormValid = false;


    // useEffect(() => {
    //   console.log('name:', watch("name"))
    //   console.log('document:', watch("document"))
    //   console.log('validation', watch("name")?.trim() !== "" && watch("document")?.trim() !== "")
    //   if(watch("name")?.trim() !== "" && watch("document")?.trim() !== ""){
    //     setTermsAccepted(true)
    //   }else{
    //     setTermsAccepted(false)
    //   }
    // }, [watch("name")])

    // useEffect(() => {
    //   console.log('name:', watch("name"))
    //   console.log('document:', watch("document"))
    //   console.log('validation', watch("name")?.trim() !== "" && watch("document")?.trim() !== "")
    //   if(watch("name")?.trim() !== "" && watch("document")?.trim() !== ""){
    //     setTermsAccepted(true)
    //   }else{
    //     setTermsAccepted(false)
    //   }
    // }, [watch("document")])


    const validationCheck = (domEvent:any, domField:any) => {
      let e = domEvent.target.value
      console.log(e)

      console.log('name:', watch("name"))
      console.log('document:', watch("document"))

      if(e.trim() === ''){
        setTermsAccepted(false)
      }else if(domField === 'document' && getValues('name').trim() === ''){
        setTermsAccepted(false)
      }else if(domField === 'name' && getValues('document').trim() === ''){
        setTermsAccepted(false)
      }else{
        setTermsAccepted(true)
      }
    }

    const countries = [
      { language: "en", slug: 'af', name: 'Afghanistan', flag: '🇦🇫' },
      { language: "en", slug: 'al', name: 'Albania', flag: '🇦🇱' },
      { language: "en", slug: 'dz', name: 'Algeria', flag: '🇩🇿' },
      { language: "en", slug: 'as', name: 'American Samoa', flag: '🇦🇸' },
      { language: "en", slug: 'ad', name: 'Andorra', flag: '🇦🇩' },
      { language: "pt", slug: 'ao', name: 'Angola', flag: '🇦🇴' },
      { language: "en", slug: 'ai', name: 'Anguilla', flag: '🇦🇮' },
      { language: "en", slug: 'aq', name: 'Antarctica', flag: '🇦🇶' },
      { language: "en", slug: 'ag', name: 'Antigua and Barbuda', flag: '🇦🇬' },
      { language: "es", slug: 'ar', name: 'Argentina', flag: '🇦🇷' },
      { language: "en", slug: 'am', name: 'Armenia', flag: '🇦🇲' },
      { language: "en", slug: 'aw', name: 'Aruba', flag: '🇦🇼' },
      { language: "en", slug: 'au', name: 'Australia', flag: '🇦🇺' },
      { language: "en", slug: 'at', name: 'Austria', flag: '🇦🇹' },
      { language: "en", slug: 'az', name: 'Azerbaijan', flag: '🇦🇿' },
      { language: "en", slug: 'bs', name: 'Bahamas', flag: '🇧🇸' },
      { language: "en", slug: 'bh', name: 'Bahrain', flag: '🇧🇭' },
      { language: "en", slug: 'bd', name: 'Bangladesh', flag: '🇧🇩' },
      { language: "en", slug: 'bb', name: 'Barbados', flag: '🇧🇧' },
      { language: "en", slug: 'by', name: 'Belarus', flag: '🇧🇾' },
      { language: "en", slug: 'be', name: 'Belgium', flag: '🇧🇪' },
      { language: "en", slug: 'bz', name: 'Belize', flag: '🇧🇿' },
      { language: "en", slug: 'bj', name: 'Benin', flag: '🇧🇯' },
      { language: "en", slug: 'bm', name: 'Bermuda', flag: '🇧🇲' },
      { language: "en", slug: 'bt', name: 'Bhutan', flag: '🇧🇹' },
      { language: "es", slug: 'bo', name: 'Bolivia', flag: '🇧🇴' },
      { language: "en", slug: 'ba', name: 'Bosnia and Herzegovina', flag: '🇧🇦' },
      { language: "en", slug: 'bw', name: 'Botswana', flag: '🇧🇼' },
      { language: "pt", slug: 'br', name: 'Brasil', flag: '🇧🇷' },
      { language: "en", slug: 'io', name: 'British Indian Ocean Territory', flag: '🇮🇴' },
      { language: "en", slug: 'bn', name: 'Brunei', flag: '🇧🇳' },
      { language: "en", slug: 'bg', name: 'Bulgaria', flag: '🇧🇬' },
      { language: "en", slug: 'bf', name: 'Burkina Faso', flag: '🇧🇫' },
      { language: "en", slug: 'bi', name: 'Burundi', flag: '🇧🇮' },
      { language: "pt", slug: 'cv', name: 'Cabo Verde', flag: '🇨🇻' },
      { language: "en", slug: 'kh', name: 'Cambodia', flag: '🇰🇭' },
      { language: "en", slug: 'cm', name: 'Cameroon', flag: '🇨🇲' },
      { language: "en", slug: 'ca', name: 'Canada', flag: '🇨🇦' },
      { language: "en", slug: 'ky', name: 'Cayman Islands', flag: '🇰🇾' },
      { language: "en", slug: 'cf', name: 'Central African Republic', flag: '🇨🇫' },
      { language: "en", slug: 'td', name: 'Chad', flag: '🇹🇩' },
      { language: "es", slug: 'cl', name: 'Chile', flag: '🇨🇱' },
      { language: "en", slug: 'cn', name: 'China', flag: '🇨🇳' },
      { language: "en", slug: 'cx', name: 'Christmas Island', flag: '🇨🇽' },
      { language: "en", slug: 'cc', name: 'Cocos (Keeling) Islands', flag: '🇨🇨' },
      { language: "es", slug: 'col', name: 'Colombia', flag: '🇨🇴' },
      { language: "en", slug: 'km', name: 'Comoros', flag: '🇰🇲' },
      { language: "en", slug: 'cg', name: 'Congo', flag: '🇨🇬' },
      { language: "en", slug: 'cd', name: 'Congo (DRC)', flag: '🇨🇩' },
      { language: "en", slug: 'ck', name: 'Cook Islands', flag: '🇨🇰' },
      { language: "es", slug: 'cr', name: 'Costa Rica', flag: '🇨🇷' },
      { language: "en", slug: 'ci', name: 'Côte dIvoire', flag: '🇨🇮' },
      { language: "en", slug: 'hr', name: 'Croatia', flag: '🇭🇷' },
      { language: "es", slug: 'cu', name: 'Cuba', flag: '🇨🇺' },
      { language: "en", slug: 'cw', name: 'Curaçao', flag: '🇨🇼' },
      { language: "en", slug: 'cy', name: 'Cyprus', flag: '🇨🇾' },
      { language: "en", slug: 'cz', name: 'Czechia', flag: '🇨🇿' },
      { language: "en", slug: 'dk', name: 'Denmark', flag: '🇩🇰' },
      { language: "en", slug: 'dj', name: 'Djibouti', flag: '🇩🇯' },
      { language: "en", slug: 'dm', name: 'Dominica', flag: '🇩🇲' },
      { language: "es", slug: 'do', name: 'Dominican Republic', flag: '🇩🇴' },
      { language: "es", slug: 'ec', name: 'Ecuador', flag: '🇪🇨' },
      { language: "en", slug: 'eg', name: 'Egypt', flag: '🇪🇬' },
      { language: "es", slug: 'sv', name: 'El Salvador', flag: '🇸🇻' },
      { language: "pt", slug: 'gq', name: 'Equatorial Guinea', flag: '🇬🇶' },
      { language: "en", slug: 'er', name: 'Eritrea', flag: '🇪🇷' },
      { language: "en", slug: 'ee', name: 'Estonia', flag: '🇪🇪' },
      { language: "en", slug: 'sz', name: 'Eswatini', flag: '🇸🇿' },
      { language: "en", slug: 'et', name: 'Ethiopia', flag: '🇪🇹' },
      { language: "en", slug: 'fk', name: 'Falkland Islands', flag: '🇫🇰' },
      { language: "en", slug: 'fo', name: 'Faroe Islands', flag: '🇫🇴' },
      { language: "en", slug: 'fj', name: 'Fiji', flag: '🇫🇯' },
      { language: "en", slug: 'fi', name: 'Finland', flag: '🇫🇮' },
      { language: "en", slug: 'fr', name: 'France', flag: '🇫🇷' },
      { language: "en", slug: 'gf', name: 'French Guiana', flag: '🇬🇫' },
      { language: "en", slug: 'pf', name: 'French Polynesia', flag: '🇵🇫' },
      { language: "en", slug: 'ga', name: 'Gabon', flag: '🇬🇦' },
      { language: "en", slug: 'gm', name: 'Gambia', flag: '🇬🇲' },
      { language: "en", slug: 'ge', name: 'Georgia', flag: '🇬🇪' },
      { language: "en", slug: 'de', name: 'Germany', flag: '🇩🇪' },
      { language: "en", slug: 'gh', name: 'Ghana', flag: '🇬🇭' },
      { language: "en", slug: 'gi', name: 'Gibraltar', flag: '🇬🇮' },
      { language: "en", slug: 'gr', name: 'Greece', flag: '🇬🇷' },
      { language: "en", slug: 'gl', name: 'Greenland', flag: '🇬🇱' },
      { language: "en", slug: 'gd', name: 'Grenada', flag: '🇬🇩' },
      { language: "en", slug: 'gp', name: 'Guadeloupe', flag: '🇬🇵' },
      { language: "en", slug: 'gu', name: 'Guam', flag: '🇬🇺' },
      { language: "es", slug: 'gt', name: 'Guatemala', flag: '🇬🇹' },
      { language: "en", slug: 'gg', name: 'Guernsey', flag: '🇬🇬' },
      { language: "en", slug: 'gn', name: 'Guinea', flag: '🇬🇳' },
      { language: "pt", slug: 'gw', name: 'Guinea-Bissau', flag: '🇬🇼' },
      { language: "en", slug: 'gy', name: 'Guyana', flag: '🇬🇾' },
      { language: "en", slug: 'ht', name: 'Haiti', flag: '🇭🇹' },
      { language: "es", slug: 'hn', name: 'Honduras', flag: '🇭🇳' },
      { language: "en", slug: 'hk', name: 'Hong Kong', flag: '🇭🇰' },
      { language: "en", slug: 'hu', name: 'Hungary', flag: '🇭🇺' },
      { language: "en", slug: 'is', name: 'Iceland', flag: '🇮🇸' },
      { language: "en", slug: 'in', name: 'India', flag: '🇮🇳' },
      { language: "en", slug: 'id', name: 'Indonesia', flag: '🇮🇩' },
      { language: "en", slug: 'ir', name: 'Iran', flag: '🇮🇷' },
      { language: "en", slug: 'iq', name: 'Iraq', flag: '🇮🇶' },
      { language: "en", slug: 'ie', name: 'Ireland', flag: '🇮🇪' },
      { language: "en", slug: 'im', name: 'Isle of Man', flag: '🇮🇲' },
      { language: "en", slug: 'il', name: 'Israel', flag: '🇮🇱' },
      { language: "en", slug: 'it', name: 'Italy', flag: '🇮🇹' },
      { language: "en", slug: 'jm', name: 'Jamaica', flag: '🇯🇲' },
      { language: "en", slug: 'jp', name: 'Japan', flag: '🇯🇵' },
      { language: "en", slug: 'je', name: 'Jersey', flag: '🇯🇪' },
      { language: "en", slug: 'jo', name: 'Jordan', flag: '🇯🇴' },
      { language: "en", slug: 'kz', name: 'Kazakhstan', flag: '🇰🇿' },
      { language: "en", slug: 'ke', name: 'Kenya', flag: '🇰🇪' },
      { language: "en", slug: 'ki', name: 'Kiribati', flag: '🇰🇮' },
      { language: "en", slug: 'kp', name: 'North Korea', flag: '🇰🇵' },
      { language: "en", slug: 'kr', name: 'South Korea', flag: '🇰🇷' },
      { language: "en", slug: 'xk', name: 'Kosovo', flag: '🇽🇰' },
      { language: "en", slug: 'kw', name: 'Kuwait', flag: '🇰🇼' },
      { language: "en", slug: 'kg', name: 'Kyrgyzstan', flag: '🇰🇬' },
      { language: "en", slug: 'la', name: 'Laos', flag: '🇱🇦' },
      { language: "en", slug: 'lv', name: 'Latvia', flag: '🇱🇻' },
      { language: "en", slug: 'lb', name: 'Lebanon', flag: '🇱🇧' },
      { language: "en", slug: 'ls', name: 'Lesotho', flag: '🇱🇸' },
      { language: "en", slug: 'lr', name: 'Liberia', flag: '🇱🇷' },
      { language: "en", slug: 'ly', name: 'Libya', flag: '🇱🇾' },
      { language: "en", slug: 'li', name: 'Liechtenstein', flag: '🇱🇮' },
      { language: "en", slug: 'lt', name: 'Lithuania', flag: '🇱🇹' },
      { language: "en", slug: 'lu', name: 'Luxembourg', flag: '🇱🇺' },
      { language: "en", slug: 'mo', name: 'Macao', flag: '🇲🇴' },
      { language: "en", slug: 'mg', name: 'Madagascar', flag: '🇲🇬' },
      { language: "en", slug: 'mw', name: 'Malawi', flag: '🇲🇼' },
      { language: "en", slug: 'my', name: 'Malaysia', flag: '🇲🇾' },
      { language: "en", slug: 'mv', name: 'Maldives', flag: '🇲🇻' },
      { language: "en", slug: 'ml', name: 'Mali', flag: '🇲🇱' },
      { language: "en", slug: 'mt', name: 'Malta', flag: '🇲🇹' },
      { language: "en", slug: 'mh', name: 'Marshall Islands', flag: '🇲🇭' },
      { language: "en", slug: 'mq', name: 'Martinique', flag: '🇲🇶' },
      { language: "en", slug: 'mr', name: 'Mauritania', flag: '🇲🇷' },
      { language: "en", slug: 'mu', name: 'Mauritius', flag: '🇲🇺' },
      { language: "en", slug: 'yt', name: 'Mayotte', flag: '🇾🇹' },
      { language: "es", slug: 'mx', name: 'Mexico', flag: '🇲🇽' },
      { language: "en", slug: 'fm', name: 'Micronesia', flag: '🇫🇲' },
      { language: "en", slug: 'md', name: 'Moldova', flag: '🇲🇩' },
      { language: "en", slug: 'mc', name: 'Monaco', flag: '🇲🇨' },
      { language: "en", slug: 'mn', name: 'Mongolia', flag: '🇲🇳' },
      { language: "en", slug: 'me', name: 'Montenegro', flag: '🇲🇪' },
      { language: "en", slug: 'ms', name: 'Montserrat', flag: '🇲🇸' },
      { language: "en", slug: 'ma', name: 'Morocco', flag: '🇲🇦' },
      { language: "pt", slug: 'mz', name: 'Mozambique', flag: '🇲🇿' },
      { language: "en", slug: 'mm', name: 'Myanmar', flag: '🇲🇲' },
      { language: "en", slug: 'na', name: 'Namibia', flag: '🇳🇦' },
      { language: "en", slug: 'nr', name: 'Nauru', flag: '🇳🇷' },
      { language: "en", slug: 'np', name: 'Nepal', flag: '🇳🇵' },
      { language: "en", slug: 'nl', name: 'Netherlands', flag: '🇳🇱' },
      { language: "en", slug: 'nc', name: 'New Caledonia', flag: '🇳🇨' },
      { language: "en", slug: 'nz', name: 'New Zealand', flag: '🇳🇿' },
      { language: "es", slug: 'ni', name: 'Nicaragua', flag: '🇳🇮' },
      { language: "en", slug: 'ne', name: 'Niger', flag: '🇳🇪' },
      { language: "en", slug: 'ng', name: 'Nigeria', flag: '🇳🇬' },
      { language: "en", slug: 'nu', name: 'Niue', flag: '🇳🇺' },
      { language: "en", slug: 'nf', name: 'Norfolk Island', flag: '🇳🇫' },
      { language: "en", slug: 'mk', name: 'North Macedonia', flag: '🇲🇰' },
      { language: "en", slug: 'mp', name: 'Northern Mariana Islands', flag: '🇲🇵' },
      { language: "en", slug: 'no', name: 'Norway', flag: '🇳🇴' },
      { language: "en", slug: 'om', name: 'Oman', flag: '🇴🇲' },
      { language: "en", slug: 'pk', name: 'Pakistan', flag: '🇵🇰' },
      { language: "en", slug: 'pw', name: 'Palau', flag: '🇵🇼' },
      { language: "en", slug: 'ps', name: 'Palestine', flag: '🇵🇸' },
      { language: "es", slug: 'pa', name: 'Panama', flag: '🇵🇦' },
      { language: "en", slug: 'pg', name: 'Papua New Guinea', flag: '🇵🇬' },
      { language: "es", slug: 'py', name: 'Paraguay', flag: '🇵🇾' },
      { language: "es", slug: 'pe', name: 'Peru', flag: '🇵🇪' },
      { language: "en", slug: 'ph', name: 'Philippines', flag: '🇵🇭' },
      { language: "en", slug: 'pl', name: 'Poland', flag: '🇵🇱' },
      { language: "pt", slug: 'pt', name: 'Portugal', flag: '🇵🇹' },
      { language: "es", slug: 'pr', name: 'Puerto Rico', flag: '🇵🇷' },
      { language: "en", slug: 'qa', name: 'Qatar', flag: '🇶🇦' },
      { language: "en", slug: 're', name: 'Réunion', flag: '🇷🇪' },
      { language: "en", slug: 'ro', name: 'Romania', flag: '🇷🇴' },
      { language: "en", slug: 'ru', name: 'Russia', flag: '🇷🇺' },
      { language: "en", slug: 'rw', name: 'Rwanda', flag: '🇷🇼' },
      { language: "en", slug: 'ws', name: 'Samoa', flag: '🇼🇸' },
      { language: "en", slug: 'sm', name: 'San Marino', flag: '🇸🇲' },
      { language: "pt", slug: 'st', name: 'Sao Tome and Principe', flag: '🇸🇹' },
      { language: "en", slug: 'sa', name: 'Saudi Arabia', flag: '🇸🇦' },
      { language: "en", slug: 'sn', name: 'Senegal', flag: '🇸🇳' },
      { language: "en", slug: 'rs', name: 'Serbia', flag: '🇷🇸' },
      { language: "en", slug: 'sc', name: 'Seychelles', flag: '🇸🇨' },
      { language: "en", slug: 'sl', name: 'Sierra Leone', flag: '🇸🇱' },
      { language: "en", slug: 'sg', name: 'Singapore', flag: '🇸🇬' },
      { language: "en", slug: 'sx', name: 'Sint Maarten', flag: '🇸🇽' },
      { language: "en", slug: 'sk', name: 'Slovakia', flag: '🇸🇰' },
      { language: "en", slug: 'si', name: 'Slovenia', flag: '🇸🇮' },
      { language: "en", slug: 'sb', name: 'Solomon Islands', flag: '🇸🇧' },
      { language: "en", slug: 'so', name: 'Somalia', flag: '🇸🇴' },
      { language: "en", slug: 'za', name: 'South Africa', flag: '🇿🇦' },
      { language: "en", slug: 'gs', name: 'South Georgia and South Sandwich Islands', flag: '🇬🇸' },
      { language: "en", slug: 'ss', name: 'South Sudan', flag: '🇸🇸' },
      { language: "es", slug: 'es', name: 'Spain', flag: '🇪🇸' },
      { language: "en", slug: 'lk', name: 'Sri Lanka', flag: '🇱🇰' },
      { language: "en", slug: 'sd', name: 'Sudan', flag: '🇸🇩' },
      { language: "en", slug: 'sr', name: 'Suriname', flag: '🇸🇷' },
      { language: "en", slug: 'sj', name: 'Svalbard and Jan Mayen', flag: '🇸🇯' },
      { language: "en", slug: 'se', name: 'Sweden', flag: '🇸🇪' },
      { language: "en", slug: 'ch', name: 'Switzerland', flag: '🇨🇭' },
      { language: "en", slug: 'sy', name: 'Syria', flag: '🇸🇾' },
      { language: "en", slug: 'tw', name: 'Taiwan', flag: '🇹🇼' },
      { language: "en", slug: 'tj', name: 'Tajikistan', flag: '🇹🇯' },
      { language: "en", slug: 'tz', name: 'Tanzania', flag: '🇹🇿' },
      { language: "en", slug: 'th', name: 'Thailand', flag: '🇹🇭' },
      { language: "pt", slug: 'tl', name: 'Timor-Leste', flag: '🇹🇱' },
      { language: "en", slug: 'tg', name: 'Togo', flag: '🇹🇬' },
      { language: "en", slug: 'tk', name: 'Tokelau', flag: '🇹🇰' },
      { language: "en", slug: 'to', name: 'Tonga', flag: '🇹🇴' },
      { language: "en", slug: 'tt', name: 'Trinidad and Tobago', flag: '🇹🇹' },
      { language: "en", slug: 'tn', name: 'Tunisia', flag: '🇹🇳' },
      { language: "en", slug: 'tr', name: 'Turkey', flag: '🇹🇷' },
      { language: "en", slug: 'tm', name: 'Turkmenistan', flag: '🇹🇲' },
      { language: "en", slug: 'tc', name: 'Turks and Caicos Islands', flag: '🇹🇨' },
      { language: "en", slug: 'tv', name: 'Tuvalu', flag: '🇹🇻' },
      { language: "en", slug: 'ug', name: 'Uganda', flag: '🇺🇬' },
      { language: "en", slug: 'ua', name: 'Ukraine', flag: '🇺🇦' },
      { language: "en", slug: 'ae', name: 'United Arab Emirates', flag: '🇦🇪' },
      { language: "en", slug: 'gb', name: 'United Kingdom', flag: '🇬🇧' },
      { language: "en", slug: 'us', name: 'United States', flag: '🇺🇸' },
      { language: "es", slug: 'uy', name: 'Uruguay', flag: '🇺🇾' },
      { language: "en", slug: 'uz', name: 'Uzbekistan', flag: '🇺🇿' },
      { language: "en", slug: 'vu', name: 'Vanuatu', flag: '🇻🇺' },
      { language: "en", slug: 'va', name: 'Vatican City', flag: '🇻🇦' },
      { language: "es", slug: 've', name: 'Venezuela', flag: '🇻🇪' },
      { language: "en", slug: 'vn', name: 'Vietnam', flag: '🇻🇳' },
      { language: "en", slug: 'vg', name: 'British Virgin Islands', flag: '🇻🇬' },
      { language: "en", slug: 'vi', name: 'U.S. Virgin Islands', flag: '🇻🇮' },
      { language: "en", slug: 'wf', name: 'Wallis and Futuna', flag: '🇼🇫' },
      { language: "en", slug: 'eh', name: 'Western Sahara', flag: '🇪🇭' },
      { language: "en", slug: 'ye', name: 'Yemen', flag: '🇾🇪' },
      { language: "en", slug: 'zm', name: 'Zambia', flag: '🇿🇲' },
      { language: "en", slug: 'zw', name: 'Zimbabwe', flag: '🇿🇼' }
    ];
   
    const [selectedCountry, setSelectedCountry] = useState('br');

    const handleChange = (event:any) => {
      
      
      const selectedCountry = countries.find(country => country.slug === event.target.value);

      i18n.changeLanguage(selectedCountry?.language);
      setSelectedCountry(event.target.value);
      setTermsAccepted(false)
      setValue('document', null)
    };
  
    return (
        <>
          <p className='bepass-text-default'>
              {t('signup.messages.register_message')}
          </p>
            <form className="signup-form bepass-form" onSubmit={handleSubmit(formSubmitter)}>
            <FormControl  variant="standard">
            <InputLabel id="country-select-label">{t('signup.labels.home_country')}</InputLabel>
            <Select
              labelId="country-select-label"
              value={selectedCountry}
              {...register('nationality')}
              onChange={handleChange}
            >
              {countries.map((country) => (
                <MenuItem data-language={country.language} key={country.slug} value={country.slug}>
                  <i className="country-flag">
                  <Flag className='select-country-flag' code={country.slug} fallback={ <span>{country.flag}</span> }/>
                  </i>
                  {` ${country.name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                {t('signup.labels.document')}
                </InputLabel>
                <Input
                  type="text"
                  id="document"
                  {...register('document', {
                    validate: (value) => {
                      if (selectedCountry === 'br') {
                        const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
                        return cpfRegex.test(value) || t('signup.errors.invalid_cpf');
                      } else {
                        return value.length > 0 || t('signup.errors.required');
                      }
                    }
                  })}
                  placeholder={selectedCountry === 'br' ? t('signup.placeholders.document') : t('signup.placeholders.passport')}
                  onChange={(e) => {
                    if (selectedCountry === 'br') {
                      let value = e.target.value.replace(/\D/g, '');
                      value = value.replace(/(\d{3})(\d)/, '$1.$2');
                      value = value.replace(/(\d{3})(\d)/, '$1.$2');
                      value = value.replace(/(\d{3})(\d{1,14})/, '$1-$2');
                      e.target.value = value.slice(0, 14);
                      validationCheck(e, 'document')
                    }else{
                      validationCheck(e, 'document')
                    }
                  }}
                />
              </FormControl>

              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                {t('signup.labels.name')}
                </InputLabel>
                <Input
                  type="text" 
                  id="name" 
                  {...register('name')}
                  onChange={(e) => {
                    validationCheck(e, 'name')
                  }}
                  placeholder={t('signup.placeholders.name')}
                />
              </FormControl>


              {errors.document && (
                <span className="label-error">
                 {t('signup.errors.document_required')}
                </span>
              )}

              {errors.name && (
                <span className="label-error">
                 {t('signup.errors.name_required')}
                </span>
              )}

              <button 
                    type="submit" 
                    className="continue-button" 
                    // onClick={onNext}
                    disabled={!termsAccepted}
                >
                    {t('signup.buttons.continue')}
                </button>

            </form>
            {isMobile && ( 
                <div className="footer">
                    <img src={bepass_white_logo} alt="Bepass Logo" className="footer-logo" />
                </div>
            )}
        </>
    );
};

export default SignupPartOne;