import React, {useEffect} from 'react'
import './MobileHandler.sass'
import {useTranslation} from "react-i18next";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from 'react-device-detect'


const MobileHandler = () => {
  const [currentUrl, setCurrentUrl] = React.useState(window.location.origin)
  const [userToken, setUserToken] = React.useState('')
  const [originCompany, setOriginCompany] = React.useState('')
  const { t } = useTranslation()


  useEffect(() => {

    const params = new URLSearchParams(window.location.search)
    const currentToken:any = params.get('token');
    const currentOrc:any = params.get('orc');

    if(currentToken){
      console.log(currentToken)
      sessionStorage.setItem('token', currentToken )
      sessionStorage.setItem('orc', currentOrc )
    }


    if(currentToken || window.sessionStorage.getItem('token')){
      if(isMobile){
        window.location.href = `/selfie?token=${currentToken}`
      }
    }

    if (window.sessionStorage.getItem('token')) {
      setUserToken(window.sessionStorage.getItem('token') + '')
      setOriginCompany(window.sessionStorage.getItem('orc') + '')
    }
  }, [])
    return (

      <div className='card-instructions'>
      <h1 className='bepass-title'>{t("mobile_handler.instruction_title")}</h1>
      <p className='bepass-text-default'>{t("mobile_handler.instruction_text")}</p>
      <div className="qr-code">
          <img src={
              `https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=${currentUrl}?token=` + userToken}
               alt="QR Code"
          />
      </div>
          <p className='bepass-text-label'>{t("mobile_handler.instruction_link_label")}
          <a
        className="qr-link"
        href={`${currentUrl}?token=${userToken}&orc=` + originCompany}
      >
        {`${currentUrl}?token=${userToken}&orc=` + originCompany}
      </a>
      </p>
  </div>

    )
}
export default MobileHandler
