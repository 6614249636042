import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./../components/Loader/Loader.sass"
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from 'react-device-detect'
import { apiUser } from "../basedata/apiCall";

const Validating = () => {

  const { t } = useTranslation(); 
  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();

  const handleAcceptTerms = () => {
    setAccepted(!accepted);
  };

  const handleContinue = () => {
    if (accepted) {
      navigate("/signup");
    }
  }

  const getUserFlow = async (userToken:any) => {
    console.log('meu grande e louro pirux')
    try{

      const _response = await apiUser.post(`/biometry/flow/user-jorney`, {flowId: userToken})

      if(_response.data.status === 'success'){
        sessionStorage.setItem('user_history', _response.data.message)
        sessionStorage.setItem('get_flow_index', 'true')
        
        const _current_location = window.location.pathname
        if(_response.data.message === 'selfie'){
          if(!/selfie/.test(_current_location)){
            window.location.href = 'selfie'
          }
        }else if(_response.data.message === 'document_front'){
          if(!/mobile-document/.test(_current_location)){
            window.location.href = 'mobile-document'
          }
        }else if(_response.data.message === 'document_back'){
          if(!/mobile-document/.test(_current_location)){
            window.location.href = 'mobile-document?step=document_back'
          }
        }else if(_response.data.message === 'done'){
          if(!/success/.test(_current_location)){
            window.location.href = 'success'
          }
        }
      }

    }catch(e){
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    }
    return true
  }
  

  useEffect(() => {

    const params = new URLSearchParams(window.location.search)
    const currentToken = params.get('token'),
    currentCompany = params.get('orc')

    if(currentToken){
      sessionStorage.setItem('token', `${currentToken}`)
      sessionStorage.setItem('originCompany', `${currentCompany}`)
    }

    getUserFlow(currentToken);

    // if(isMobile){
    //   window.location.href = `/selfie?token=${currentToken}`
    // }else{
    //   window.location.href = `/mobile-handler?token=${currentToken}`
    // }
  }, []);


  return (
    <div className="loader">
        <div className="spinner">
        </div>
    </div>
  );
};

export default Validating;

