import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SuccessHandler.sass";
import success_image from "../images/Illustration-success.png";
import { BASE } from "../basedata/constants";

const SuccessHandler = () => {
  const { t } = useTranslation(); 
  const [accepted, setAccepted] = useState(false);



  useEffect(() => {
    const _temp_language = sessionStorage.getItem('language') || 'pt'
    sessionStorage.clear()
    sessionStorage.setItem('success_flow', 'true')
    sessionStorage.setItem('language', _temp_language)
  }, []);


  return (
    <div className="success-container">
      <div className="progress-indicator">
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="progress-bar"></div>
        ))}
      </div>
      <div className="left-side"></div> 
      <div className="right-side">
        <div className="success-card">
          <img className="logo" src={BASE.company.logo} alt={BASE.company.name} />
          <h1 className="success_title">
            Uhuuu!
          </h1>
          <div className="success-message-container">
            <p className="success-message">
            <strong>{t("success_page.sub_title")}</strong> <br/><br/>
            {t("success_page.text_1")}
            </p>
              {/* <img src={success_image} className="no-svg-tip" alt="Sucesso!" /> */}
            <p className="success-message">
            {t("success_page.text_2")}<br/><br/>  
            {t("success_page.see_ya")}
            </p>
            <button onClick={() => {window.location.href = 'https://ingressos.flamengo.com.br'}} className="continue-button"
    >
      {t("success_page.action_button")}
    </button>
          </div>
        </div>
      </div>
      {/* <footer className="success-footer">
        <div className="success-footer-logo"></div>
      </footer> */}
    </div>
  );
};

export default SuccessHandler;