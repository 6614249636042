import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SelfieCapture.sass";
import InfoModal from '../components/Modal/ModalInfo'
import FaceAutoCapture from "../components/FaceAutoCapture/FaceAutoCapture";
import BottomTab from "../components/Tab/BottomTab";
import FacialInstructions from "../components/Intructions/FacialInstructions";
import SelfieDone from "../components/SelfieDone/SelfieDone";
import SelfieSuccess from "../components/SelfieSuccess/SelfieSuccess";
import SelfieInvalid from "../components/SelfieInvalid/SelfieInvalid";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from 'react-device-detect'

interface Props {
    onResponse?: ( message:any ) => void;
}

const SelfieCapture = ({onResponse} : Props) => {
    const { t } = useTranslation();
    const [selfieBase64, setSelfieBase64] = useState<string | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [userState, setUserState] = useState<string>('face')
    const [selfieAttempts, setSelfieAttempts] = useState<number>(0)

    const handlePhotoTaken = (base64Photo: string) => {
        setSelfieBase64(base64Photo)
        console.log('meu piru no uno~')
        // console.log(base64Photo)
    }

    const reCapturePhoto = () => {
        setSelfieBase64(null)
    }

    const handleUserResponse = (response:any) => {
        if (response.status === 'success') {
          console.log('meu naris')
          console.log(response)
          console.log('nosso!')
            setUserState('selfieDone')
        } else {
            reCapturePhoto()
        }
    }

    const handleRetry = () => {
      console.log('rety? mtf')
        setSelfieAttempts((prevAttempts) => prevAttempts + 1)
        setUserState('face')
        reCapturePhoto()
    }

    const handleError = (error: Error) => {
        console.error(error)
    }

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const togglePanel = () => {
        setIsPanelOpen((prev) => !prev);
    };


  useEffect(() => {

    const alreadly_done = sessionStorage.getItem('success_flow') || null
    if(alreadly_done){
      window.location.href = '/success'
    }

    const params = new URLSearchParams(window.location.search)
    let currentToken = params.get('token'),
    currentCompany = params.get('orc')

    if(currentToken){
      sessionStorage.setItem('token', `${currentToken}`)
      sessionStorage.setItem('originCompany', `${currentCompany}`)
    }else{
      currentToken = window.sessionStorage.getItem('token')
    }

    if(isMobile){
      setIsPanelOpen(true)
      // togglePanel()
      // setTimeout(() => {
      //   togglePanel()
      // }, 8000);
    }else{
      window.location.href = `/mobile-handler?token=${currentToken}`
    }
  }, []);

    return (
        <div className={`container-selfie-capture`}>
            {!selfieBase64 ? (
                <div className="container-selfie-capture-inside"
                >
                    <InfoModal isOpen={isModalOpen} onClose={closeModal}>
                        <h1>{t("modal_selfie_intructions.title")}</h1>
                        <p>{t("modal_selfie_intructions.quote")}</p>
                    </InfoModal>
                    <div className={`${isModalOpen ? "blur-background" : ""}`}>
                        <FaceAutoCapture onPhotoTaken={handlePhotoTaken} onError={handleError} isModalOpen={isModalOpen} isPanelOpen={isPanelOpen}/>
                    </div>
                    <BottomTab
                        togglePanel={togglePanel}
                        isPanelOpen={isPanelOpen}
                    >
                        <FacialInstructions
                         handleCloseInstructions={() => {
                          setIsPanelOpen(true)
                          setTimeout(() => {
                            setIsPanelOpen(false)
                          }, 300);
                        }}
                        togglePanel={togglePanel}
                        >
                            <h1 className="instruction-title text-center">{t("capture_face.title")}</h1>
                            <p>{t("capture_face.instructions")}</p>
                        </FacialInstructions>
                    </BottomTab>
                </div>
            ) : (
                userState === 'face' ? (
                    <SelfieDone selfieBase64={selfieBase64} onResponse={handleUserResponse} selfieAttempts={selfieAttempts} />
                ) : userState === 'selfieDone' ? (
                    <SelfieSuccess selfieBase64={selfieBase64}/>
                ) : userState === "selfieInvalid" ? (
                    <SelfieInvalid selfieAttempts={selfieAttempts} handleRetryInstructions={handleRetry} selfieBase64={selfieBase64}/>
                ) : null
            )}
        </div>
    );
};

export default SelfieCapture;
