import axios, { AxiosRequestConfig , InternalAxiosRequestConfig} from 'axios';
import { BASE } from './constants';

const sessionItem:any = window.sessionStorage.getItem('user-key')
const {user_key, tenant_id} = sessionItem ? JSON.parse(sessionItem): {user_key:null, tenant_id: BASE.company.tenant}

const apiCompany = axios.create({
  baseURL: 'https://service-company-563791290358.southamerica-east1.run.app',
  headers: {
    'x-api-key': user_key,
    'x-tenant-id': tenant_id,
    'x-api-flow': 'onboarding',
  }
});

const apiEvent = axios.create({
  baseURL: 'https://events.bepass.app',
  headers: {
    'x-api-key': 'KW_sNizaSyDu_QNDPXcix4EAIXE9N9Ux_oH8g3q4dft3',
    'x-tenant-id': tenant_id,
    'x-api-flow': 'onboarding',
  }
});

const apiUser = axios.create({
  baseURL: 'https://service-user-flamengo-563791290358.southamerica-east1.run.app',
  headers: {
    'x-api-key': 'KW_sNizaSyDu_QNDPXcix4EAIXE9N9Ux_oH8g3q4dft3',
    'x-tenant-id': tenant_id,
    'x-api-flow': 'onboarding',
  }
});

const apiJaiminho = axios.create({
  baseURL: 'https://service-jaiminho-corinthians-563791290358.southamerica-east1.run.app',
  headers: {
    'x-api-key': user_key,
    'x-tenant-id': tenant_id,
    'x-api-flow': 'onboarding',
  }
});

export { apiCompany, apiEvent, apiUser, apiJaiminho };