export const BASE = {
  company: {
    logo: "https://storage.googleapis.com/companies-assets/flamengo/client_logo.png",
    background_image: "https://storage.googleapis.com/companies-assets/flamengo/flamengo_onboarding_bg.jpg",
    primaryColor: "#541b10",
    secondaryColor: "#1f1d1d",
    tertiaryColor: "#541b10",
    copyrightLogo: "default",
    backgroundColor: ["#000000", "#000000"],
    tenant: 'flamengo-zjbgi',
    name: 'Flamengo',
    bepassLogo: "https://storage.googleapis.com/companies-assets/bepass/bepass_white_logo.png",
    id: 'aac3a061-7d13-45ee-a8b3-47338bf976a9',
    dis: 0.87
  }
}