import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import bepass_white_logo from "../../images/logoCompleto.png";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRegisterData } from './SignupStepper';
import { FormControl, Input, InputLabel } from '@mui/material';
import { apiUser } from '../../basedata/apiCall';
import { BASE } from '../../basedata/constants';

import validator from 'validator';

interface FormStepperInterface {
  onBack: () => void;
}

const SignupPartTwo = ({ onBack}:FormStepperInterface) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [formData, setFormData] = useState({
        phone: '',
        birthdate: '',
        gender: '',
        email: ''
    });

  const {handleRegisterFormData} = useRegisterData()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    getValues,
    watch,
    formState: { errors },
    clearErrors
  } = useForm()

  const [termsAccepted, setTermsAccepted] = useState(false);

    const isFormValid = watch('phone') && watch('birthdate') && watch('email')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { id, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }))

        // setErrors((prevErrors) => ({
        //     ...prevErrors,
        //     [id]: value ? '' : t('signup.errors.required'),
        // }))
    }

    // const formSubmitter = (data:any) => {

    //   console.log('piutaria')
    //   console.log(data)
    //   return false
      
    // };

    const formSubmitter = async (data:any) => {
      

      const sessionOne = sessionStorage.getItem("heartPartOne");
      const partOneData = sessionOne ?JSON.parse(sessionOne) : {};
      const fullData = { ...partOneData, ...data };


      if(!data.phone){
        return setError('phone', {
           type: 'manual',
           message: 'Você precisa preencher ao menos 1 campo!'
         })
       }

       if(data.birthdate.length < 10){
        return setError('birthdate', {
           type: 'manual',
           message: 'Você precisa preencher ao menos 1 campo!'
         })
       }

       if(Number(data.birthdate.split('/')[2]) <= 1900){
        return setError('birthdate', {
           type: 'manual',
           message: 'Você precisa preencher ao menos 1 campo!'
         })
       }
       

       if(!validator.isDate(data.birthdate, {format:'DD/MM/YYYY'})){
        return setError('birthdate', {
           type: 'manual',
           message: 'Você precisa preencher ao menos 1 campo!'
         })
       }

       const today = new Date();
       const birthdateParts = data.birthdate.split('/');
       const birthdate = new Date(`${birthdateParts[2]}-${birthdateParts[1]}-${birthdateParts[0]}`);
 
       if (birthdate > today) {
         return setError('birthdate', {
           type: 'manual',
           message: 'A data de nascimento não pode ser maior que a data de hoje!'
         });
       }
       
       if(!validator.isEmail(data.email)){
        return setError('email', {
           type: 'manual',
           message: 'Você precisa preencher ao menos 1 campo!'
         })
       }
    
       
        const properName = fullData.name.replace(/^\s+|\s+$/g, '').split(' ')
        const dateFormate = data.birthdate.split('/')

        const _user_register = await apiUser.post('/user',{
          firstName: properName.shift(),
          externalId: `${fullData.document.replace(/^\s+|\s+$/g, '')}_${Math.floor(Math.random() * 291)}`,
          lastName: properName.join(' '),
          gender: '',
          document: fullData.document.replace(/^\s+|\s+$/g, ''),
          document_type: '',
          email: fullData.email.replace(/^\s+|\s+$/g, ''),
          phoneNumber: fullData.phone
            .replace(/"/g, '')
            .replace(/'/g, '')
            .replace(/\(|\)/g, ''),
          birthDate:
            dateFormate[2] + '-' + dateFormate[1] + '-' + dateFormate[0],
          nationality: '',
          originCompanyId: BASE.company.id
        })

        sessionStorage.setItem('token', _user_register?.data?.sup)
        window.location.href = `/validating?token=${_user_register.data.sup}`
        return false
        
        const newErrors = {
            phone: formData.phone ? '' : t('signup.errors.required'),
            date: formData.birthdate ? '' : t('signup.errors.required'),
            email: formData.email ? '' : t('signup.errors.required'),
            // gender: formData.gender && formData.gender !== 'start' ? '' : t('signup.errors.required'),
        }

        data.gogo = 'rola'
        handleRegisterFormData(data)
        const hasErrors = Object.values(newErrors).some((error) => error)
        if (!hasErrors) {
            // const savedData = localStorage.getItem("signupPartOne");
            // const partOneData = savedData ?JSON.parse(savedData) : {};
            // const fullData = { ...partOneData, ...formData };

            console.log(fullData);

            localStorage.setItem("signupComplete", JSON.stringify(fullData));

            navigate("/mobile-handler");
        }
    }

    // const onNext = () => {
    //   window.location.href = '/mobile-handler'
    // }
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const validationCheck = (domEvent:any, domField:any) => {
      let e = domEvent.target.value
      console.log(e)
      console.log('bday',  getValues('birthdate') === '')

      if(e.trim() === ''){
        setTermsAccepted(false)
      }else if(domField === 'birthdate' && (getValues('phone').trim() === ''  || getValues('email').trim() === '')){
        setTermsAccepted(false)
      }else if(domField === 'phone' && (getValues('birthdate').trim() === ''  || getValues('email').trim() === '')){
        setTermsAccepted(false)
      }else if(domField === 'email' && (getValues('phone').trim() === ''  || getValues('birthdate').trim() === '')){
        setTermsAccepted(false)
      }else{
        setTermsAccepted(true)
      }
    }

    return (
      <>
      <p className='bepass-text-default'>
          {t('signup.messages.register_message')}
      </p>
        <form className="signup-form bepass-form" onSubmit={handleSubmit(formSubmitter)} noValidate>
            <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Celular
            </InputLabel>
            <Input
              type="text" 
              id="phone" 
              {...register('phone')}
              placeholder={t('signup.placeholders.phone')}
              value={formData.phone}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, ''); // Remove non-digits
              
                const maskedValue = value
                  .replace(/^(\d{2})(\d)/, '($1) $2') // Add (99) at the beginning

              
                setFormData((prevData) => ({
                  ...prevData,
                  phone: maskedValue,
                }));
                validationCheck(e, 'phone')
              }}
            />
            </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              E-mail
            </InputLabel>
            <Input
              type="email" 
              id="email" 
              {...register('email')}
              onChange={(e) => {validationCheck(e, 'email')}}
              placeholder={t('signup.placeholders.email')}
            />
          </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Data de nascimento
            </InputLabel>
            <Input
              type="text" 
              id="birthdate" 
              {...register('birthdate')}
              value={formData.birthdate}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, ''); // Remove non-digits
              
                const maskedValue = value
                  .replace(/^(\d{2})(\d)/, '$1/$2')    // Add '/' after the first two digits (day)
                  .replace(/(\d{2})(\d)/, '$1/$2')    // Add '/' after the next two digits (month)
                  .replace(/(\d{4})\d+?$/, '$1');     // Keep only the first four digits (year)
              
                setFormData((prevData) => ({
                  ...prevData,
                  birthdate: maskedValue,
                }));
                validationCheck(e, 'birthdate')
              }}
              placeholder={t('signup.placeholders.birthdate')}
            />
          </FormControl>
          {errors.birthdate && (
                <span className="label-error">
                 Você precisa informar uma data de nascimento válida.
                </span>
          )}

          {errors.email && (
            <span className="label-error">
              Você precisa informar um e-mail válido.
            </span>
          )}

          {errors.phone && (
            <span className="label-error">
              Você precisa informar um telefone válido.
            </span>
          )}
          <button 
                type="submit" 
                className="continue-button" 
                // onClick={onNext}
                disabled={!termsAccepted}
            >
                {t('signup.buttons.finish')}
            </button>

            <div className="back-button" onClick={onBack}>
                <div className="icon-container">
                    <ArrowBackIcon className="arrow-icon" />
                </div>
                <span className="text">{t('signup.navigation.back_text')}</span>
            </div>

        </form>
        {isMobile && ( 
            <div className="footer">
                <img src={bepass_white_logo} alt="Bepass Logo" className="footer-logo" />
            </div>
        )}
    </>
    );
};

export default SignupPartTwo