import React, { createContext, useCallback, useContext, useState } from "react";
import SignupPartOne from "./SignupPartOne";
import SignupPartTwo from "./SignupPartTwo";
import { useNavigate } from "react-router-dom";

interface SignupStepperProps {
    activeStep: number;
    nextStep: () => void;
    prevStep: () => void;
}

const RegisterDataContext = createContext<any>(null);

const SignupStepper: React.FC<SignupStepperProps> = ({ activeStep, nextStep, prevStep }) => {

    const [userRegisterData, setUserRegisterData] = useState({});
    

    const handleRegisterFormData = useCallback((userData: any) => {
      setUserRegisterData((prevData) => ({
        ...prevData,
        ...userData,
      }));

      nextStep()
    }, [])

    
    return (
      <RegisterDataContext.Provider value={{ userRegisterData, handleRegisterFormData }}>
            {activeStep === 0 ? (
              <SignupPartOne onNext={nextStep} />
            ) : (
              <SignupPartTwo onBack={prevStep} />
            )}
        </RegisterDataContext.Provider>
    );
};

export const useRegisterData = () => {
  const navigate = useNavigate();
  const context = useContext(RegisterDataContext);
  let cancall = true
  if (!context) {
    throw new Error('useRegisterData must be used within a RegisterDataContext.Provider');
  }
  if(context.userRegisterData && context.userRegisterData.gogo === 'rola' && cancall){
    console.log('ala como vem')
    console.log('.>', context.userRegisterData)
    cancall = false
    console.log(context)
    return navigate("/mobile-handler");
  }
  return context;
};

export default SignupStepper;